import styled from 'styled-components'

interface PInTableListProps {
    fontSize?: string
    isDeleted?:boolean
}
export const PInTableList = styled.div<PInTableListProps>`
    font-size: ${(props) => props.fontSize || '10px'};
    margin: 0;
    text-decoration: ${(props) => props.isDeleted ? "line-through":""}
`

