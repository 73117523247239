import * as React from 'react'
import { DataTableModel } from './DataTableModel'
import download from 'downloadjs'
import moment from 'moment'
import './style.scss'
import { FilterType } from './Filters/FilterTypes'
import { DataTableData, SetEditCallbackFunction, SortType } from './DataTableData'
import SelectFilter from './Filters/SelectFilter'
import { PaginationModel } from '../models/PaginationModel'
import { Pagination } from '../Pagination'
import { Container } from '../Container'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import * as LoadingStore from '../store/Loading'

interface Props {
    hidden?: boolean
    data: any[]
    isHidden?: boolean
    initFilter?: any
    hideToolbar?: boolean
    hideClearFilter?: boolean
    dataTable: DataTableModel
    isFullScreenable?: boolean
    pagingInfo?: PaginationModel
    onPageChange?: (page: number) => void
    paddingBottom?: number
    onRefreshData?: () => void
    onClearFilter?: () => void
    sortField?: string
    equalsSortField?: string
    isFullScreen?: boolean
    sortType?: SortType
    fixedHeight?: number
    embededScreen?: boolean
    customBody?: React.ReactElement
    onCustomExcelDownload?: (callback: (data: any) => void) => void
    onSelectedChanged?: (selected: any[]) => void
    setSelectClearFromParentCallback?: (callback: () => void) => void
    setEditFromParentCallback?: (callback: SetEditCallbackFunction) => void
    selectIdField?: string
    onEdit?: (prevObj: any, obj: any) => void
    onEditError?: (obj: any) => any
    onDelete?: (obj: any) => void
    onFilterValueChange?: (fieldName: string, value: any) => void
    onSortChanged?: (fieldName: string, sortType: string) => void
}
// interface DataTableState {
//     showCustomActions: boolean
//     isFullScreen?: boolean
//     width: number
//     w: number
//     wScroll: number
//     paginationBlock?: HTMLDivElement
//     h: number
//     filter: any
// }
export const DataTable = (props: Props) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: ApplicationState) => state.settings)

    const [paginationBlock, setPaginationBlock] = React.useState(undefined as HTMLDivElement | undefined)
    const [isShowCustomActions, setIsShowCustomActions] = React.useState(false)
    const [width, setWidth] = React.useState(0)
    const [isRedrawnAfterPagination, setIsRedrawnAfterPagination] = React.useState(false)
    const [w, setW] = React.useState(window.innerWidth)
    const [isFullScreen, setIsFullScreen] = React.useState(props.isFullScreen)
    const [wScroll, setWScroll] = React.useState(17)
    const [h, setH] = React.useState(window.innerHeight)
    const [filter, setFilter] = React.useState(props.initFilter || {})
    // private pagination?: HTMLDivElement = undefined
    React.useEffect(() => {
        if (!paginationBlock && isRedrawnAfterPagination) {
            setIsRedrawnAfterPagination(true)
        }
        if (paginationBlock && !isRedrawnAfterPagination) {
            setIsRedrawnAfterPagination(true)
        }
    }, [paginationBlock])
    const setPaginationRef = (ref: HTMLDivElement) => {
        // this.pagination = ref
        setPaginationBlock(ref)
        setWidth(width)
    }
    const updateWindowDimensions = () => {
        setW(window.innerWidth)
        setH(window.innerHeight)
        calculateWidth(props.dataTable)
    }
    const calculateWidth = (dataTable: DataTableModel) => {
        const tdPadding = 6
        const tdBorder = 1
        const width =
            dataTable.columns.reduce((width, c) => (width += c.width + tdPadding + tdBorder), 0) + 76 - tdBorder
        setWidth(width)
    }

    React.useEffect(() => {
        calculateWidth(props.dataTable)
        window.addEventListener('resize', updateWindowDimensions)
        return () => {
            if (props.initFilter) {
                setFilter(props.initFilter)
            }
            window.removeEventListener('resize', updateWindowDimensions)
        }
    }, [])
    React.useEffect(() => {
        calculateWidth(props.dataTable)
    }, [props.dataTable])

    const getTableBodyHeight = () => {
        // const paginationBlock = paginationBlock
        const paginationPadding =
            (paginationBlock && (paginationBlock.clientHeight > 10 ? paginationBlock.clientHeight : 64)) ||
            props.paddingBottom ||
            0
        return (
            props.fixedHeight || h - (isFullScreen ? 100 : 160) - 26 - paginationPadding - wScroll
            // (width > w ? 0 : wScroll)
        )
    }
    const clearFilter = () => {
        if (props.onClearFilter) {
            props.onClearFilter()
        } else {
            setFilter({})
        }
    }
    const refreshData = () => {
        if (props.onRefreshData) {
            props.onRefreshData()
        }
    }
    const showCustomActions = () => {
        setIsShowCustomActions(!isShowCustomActions)
    }
    const getColumnValue = (obj: any, fieldName: string) => {
        return moment.isMoment(obj[fieldName])
            ? (obj[fieldName] as moment.Moment).format('DD.MM.YYYY')
            : obj[fieldName]
    }
    const downloadExcelConcrete = (data: any) => {
        const { dataTable } = props
        const payload = {
            Title: dataTable.name,
            FileName: dataTable.fileName,
            Columns: dataTable.columns
                .filter((x) => x.isExportExcel)
                .reduce((res, col) => {
                    return res.concat([
                        {
                            Title: typeof col.displayName == 'string' ? col.displayName : '',
                            Data: data.map((obj: any) => {
                                return col.customRenderer || col.customExcelRenderer
                                    ? col.customExcelRenderer
                                        ? col.customExcelRenderer(obj)
                                        : col.isUseCustomRendererForExcel
                                            ? col.customRenderer
                                                ? col.customRenderer(obj)
                                                : getColumnValue(obj, col.fieldName)
                                            : getColumnValue(obj, col.fieldName)
                                    : getColumnValue(obj, col.fieldName)
                            }),
                        },
                    ])
                }, [] as any[]),
        }
        const json = JSON.stringify(payload)
        dispatch(LoadingStore.actionCreators.incrementLoading())
        return fetch(settings.dataTableExportExcelEndpoint, {
            body: json,
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(
                (response) => response.blob(),
                (error) => {
                    alert("Couldn't generate excel file")
                    dispatch(LoadingStore.actionCreators.decrementLoading())
                },
            )
            .then((blob) => {
                dispatch(LoadingStore.actionCreators.decrementLoading())
                if (blob) {
                    download(blob, dataTable.fileName, 'xlsx')
                }
            })
    }
    const downloadExcel = () => {
        const { data } = props
        if (props.onCustomExcelDownload) {
            props.onCustomExcelDownload(downloadExcelConcrete)
        } else {
            downloadExcelConcrete(data)
        }
    }
    const onFilterValueChange = (fieldName: string, value: any) => {
        if (props.onFilterValueChange) {
            props.onFilterValueChange(fieldName, value)
        } else {
            const column = props.dataTable.columns.find(
                (x) => x.filterFieldName === fieldName || x.fieldName === fieldName,
            )
            if (column && column.onFilterValueChanged) {
                column.onFilterValueChanged(fieldName, value)
            } else {
                let newFilter = filter
                if (value.value && (Number.isInteger(value.value) || value.value.length > 0)) {
                    newFilter[fieldName] = value.value
                } else {
                    delete newFilter[fieldName]
                }
                setFilter(Object.assign({}, newFilter))
            }
        }
    }
    const sort = (data: any[], dataTableModel: DataTableModel) => {
        if (!props.onFilterValueChange) {
            dataTableModel.columns.forEach((c) => {
                const fieldName = c.filterFieldName || c.fieldName
                let filterValue = filter[fieldName]
                if (filterValue) {
                    if (c.customFilterFunc) {
                        data = c.customFilterFunc(filterValue, data)
                    } else {
                        switch (c.filterType) {
                            case FilterType.MULTISELECT:
                                data = data.filter((x) => filterValue.some((fv: any) => fv === x[fieldName]))
                                break
                            case FilterType.TEXT:
                                filterValue = filterValue.toLowerCase()
                                data = data.filter((x) => x[fieldName].toLowerCase().indexOf(filterValue) >= 0)
                                break
                            default:
                                break
                        }
                    }
                }
            })
        }
        return data
    }
    const getCustomToolBarComponent = () => {
        const { customToolBarComponents } = props.dataTable
        return customToolBarComponents && customToolBarComponents.length > 0 ? (
            <div>
                {customToolBarComponents.map((x, index) => (
                    <div className="data-table-custom-comp" key={index}>
                        {x()}
                    </div>
                ))}
            </div>
        ) : null
    }
    const onPageChange = (page: number) => {
        if (props.onPageChange) props.onPageChange(page)
    }
    const customActionClass = 'data-table-action-embeded'
    const data = React.useMemo(
        () => sort(props.data, props.dataTable),
        [props.data, props.dataTable, filter, paginationBlock],
    )
    const tableBodyHeight = getTableBodyHeight()
    // console.log("Data TAble")
    return (
        <Container isFullScreen={isFullScreen || props.embededScreen}>
            <div
                className={`content-wrap content-table-wrap${props.embededScreen ? ' embeded' : ''}${isFullScreen ? ' fullscreen' : ''
                    }`}
            >
                {!props.hideToolbar && (
                    <div
                        style={{
                            top: props.fixedHeight ? props.fixedHeight - 60 : undefined,
                            // height: props.isToolbar ? 40 : undefined,
                            bottom: props.fixedHeight ? undefined : props.embededScreen ? 120 : undefined,
                        }}
                        className={`table-toolbar${props.embededScreen ? ' embeded' : ''}`}
                    >
                        {<h3>{props.dataTable.name}</h3>}
                        {getCustomToolBarComponent()}

                        {props.dataTable.customActions && props.dataTable.customActions.length > 0 && (
                            <div>
                                {props.dataTable.customActions.map((a, index) => {
                                    return (
                                        <span
                                            title={a.title}
                                            className={`data-table-action-toolbar ${customActionClass}`}
                                            key={index}
                                            onClick={() => a.onClick()}
                                        >
                                            {a.renderer()}
                                        </span>
                                    )
                                })}
                            </div>
                        )}
                        {props.onRefreshData && (
                            <span
                                title="Refresh"
                                className={`data-table-refresh ${customActionClass}`}
                                onClick={() => {
                                    refreshData()
                                }}
                            >
                                <i className="fa fa-refresh"></i>
                            </span>
                        )}
                        <span
                            title="Download Excel"
                            className={`data-table-export-excel ${customActionClass}`}
                            onClick={downloadExcel}
                        >
                            <i className="fa fa-file-excel-o"></i>
                        </span>
                        {!props.hideClearFilter && (
                            <span
                                title="Clear Filters"
                                className={`data-table-clear-filters ${customActionClass}`}
                                onClick={clearFilter}
                            >
                                <i className="fa fa-filter"></i>
                            </span>
                        )}
                        {props.isFullScreenable && (
                            <span
                                title={isFullScreen ? 'Collapse' : 'Expand'}
                                className={`data-table-action-embeded data-table-toolbar-icon data-table-toolbar-icon-right`}
                            >
                                <i
                                    className={`fa fa-${isFullScreen ? 'compress' : 'expand'}`}
                                    onClick={() => setIsFullScreen(!isFullScreen)}
                                />
                            </span>
                        )}
                    </div>
                )}
                <DataTableData
                    customBody={props.customBody}
                    dataTable={props.dataTable}
                    equalsSortField={props.equalsSortField}
                    width={width}
                    sortField={props.sortField}
                    onFilterValueChange={onFilterValueChange}
                    data={data}
                    filter={filter}
                    onDelete={props.onDelete}
                    onEdit={props.onEdit}
                    onEditError={props.onEditError}
                    sortType={props.sortType}
                    // getTableBodyHeight={getTableBodyHeight}
                    tableTotalHeight={tableBodyHeight}
                    onSelectedChanged={props.onSelectedChanged}
                    onSortChanged={props.onSortChanged}
                    setSelectClearFromParentCallback={props.setSelectClearFromParentCallback}
                    setEditFromParentCallback={props.setEditFromParentCallback}
                    selectIdField={props.selectIdField}
                />
                {props.pagingInfo && (
                    <Pagination
                        onPageChange={onPageChange}
                        pagination={props.pagingInfo}
                        refFunc={setPaginationRef}
                    ></Pagination>
                )}
            </div>
        </Container>
    )
}
