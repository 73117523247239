import * as React from 'react'
import { DataTableModel, DataHeaderGroup } from './DataTableModel'
import { FilterType } from '../DataTable/Filters/FilterTypes'
import InputFactory from './Inputs/InputFactory'
import filterFactory from './Filters/FilterFactory'
import CheckBox from './UI/CheckBox'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import { InputType } from './Inputs/InputType'
import ReactResizeDetector from 'react-resize-detector'
import { DataTableColumnModel } from './DataTableColumnModel'
import { Tooltip } from '../Tooltip'
import { round } from '../Utils/round'

const SORT_ASC = 'ASC'
const SORT_DESC = 'DESC'
export type SortType = 'ASC' | 'DESC'
interface DataTableDataProps {
    sortField?: any
    sortType?: SortType
    equalsSortField?: string
    data: any
    customBody?: React.ReactElement
    dataTable: DataTableModel
    onDelete?: (obj: any) => void
    onEdit?: (prevObj: any, obj: any) => void
    onEditError?: (obj: any) => any
    width: number
    onFilterValueChange: (fieldName: string, value: any) => void
    // getTableBodyHeight: any
    tableTotalHeight: number
    onSelectedChanged?: (obj: any[]) => void
    setSelectClearFromParentCallback?: (callback: () => void) => void
    setEditFromParentCallback?: (callback: SetEditCallbackFunction) => void

    selectIdField?: string
    onSortChanged?: (fieldName: string, sortType: string) => void
    filter: any
}
interface DataTableDataState {
    sortType?: string
    sortField?: string
    headerHeight: number
    edits: Map<any, any>
    selected: any[]
}
export type SetEditCallbackFunction = (obj: any) => void
export class DataTableData extends React.Component<DataTableDataProps, DataTableDataState> {
    private header?: HTMLDivElement
    handleHeaderResize = (_this: DataTableData) => {
        if (this.header) {
            if (this.state.headerHeight != this.header.clientHeight) {
                this.setState({ headerHeight: this.header.clientHeight })
            }
        }
    }
    setHeaderRef = (ref: HTMLDivElement) => {
        this.header = ref
        if (this.header) this.setState({ headerHeight: this.header.clientHeight })
    }
    state = {
        selected: [] as any[],
        headerHeight: 0,
        sortField: undefined as unknown as string,
        sortType: undefined as unknown as string,
        edits: new Map<any, any>(),
    }
    componentWillUnmount() { }
    setEdit = (obj: any) => {
        this.onToggleEditMode(obj)
        // let edits = this.state.edits
        // if (isEdit) {
        //     if (!edits.has(obj)) {
        //         edits.set(obj, obj)
        //     }
        // } else {
        //     edits.delete(obj)
        // }
        // edits = new Map<any, any>(edits)
        // this.setState({ edits })
    }
    componentDidMount() {
        if (this.props.setSelectClearFromParentCallback) {
            this.props.setSelectClearFromParentCallback(this.clearSelects)
        }
        if (this.props.setEditFromParentCallback) {
            this.props.setEditFromParentCallback(this.setEdit)
        }
        this.setState({ sortField: this.props.sortField, sortType: this.props.sortType })
    }
    shouldComponentUpdate(nextProps: DataTableDataProps, nextState: DataTableDataState) {
        return (
            nextProps.width != this.props.width ||
            nextProps.data != this.props.data ||
            nextProps.dataTable != this.props.dataTable ||
            nextProps.filter != this.props.filter ||
            nextState.edits != this.state.edits ||
            nextState.sortType != this.state.sortType ||
            nextState.sortField != this.state.sortField ||
            nextProps.customBody != this.props.customBody ||
            nextState.selected !== this.state.selected ||
            nextProps.tableTotalHeight !== this.props.tableTotalHeight
        )
    }
    getSumsRowData(columns: DataTableColumnModel[], data: any[]) {
        return columns.reduce((res, column) => {
            if (column.showSum || column.showAve) {
                let value = data.reduce((sum, obj) => {
                    return column.customSumFunc ? sum + column.customSumFunc(obj) : sum + obj[column.fieldName]
                }, 0)
                if (column.showAve) {
                    value = value / data.length
                }
                return res.concat([
                    {
                        empty: false,
                        colSpan: 0,
                        value,
                    },
                ])
            } else {
                if (res.length == 0 || (res.length > 0 && !res[res.length - 1].empty)) {
                    return res.concat([{ empty: true, colSpan: 1 }])
                } else {
                    let sum = res[res.length - 1]
                    sum.colSpan++
                    return res
                }
            }
        }, [] as any)
    }
    sort(data: any) {
        // let data = this.props.data;
        if (!this.props.onSortChanged) {
            const { sortField, sortType } = this.state
            if (sortField && data && data.length > 0) {
                const column = this.props.dataTable.columns.find((x) => x.fieldName == sortField)
                if (column && column.sortFunc) {
                    data = data.sort(column.sortFunc(sortType, sortField))
                } else {
                    if (sortField && sortType) {
                        data = data.sort(this.defaultSort(sortField, sortType))
                    }
                }
            }
        }
        return data
    }
    defaultSort = (sortField: string, sortType: string) => (obj1: any, obj2: any) => {
        if (obj1[sortField] > obj2[sortField]) {
            return sortType == 'ASC' ? 1 : -1
        } else {
            if (obj1[sortField] < obj2[sortField]) {
                return sortType == 'ASC' ? -1 : 1
            } else {
                const esf = this.props.equalsSortField
                if (esf) {
                    return obj1[esf] > obj2[esf] ? 1 : obj1[esf] < obj2[esf] ? -1 : 0
                } else {
                    return obj1.Id > obj2.Id ? 1 : obj1.Id < obj2.Id ? -1 : 0
                }
            }
        }
    }
    onToggleEditMode = (obj: any) => {
        if (this.props.onEditError && this.props.onEditError(obj)) {
            alert(this.props.onEditError(obj))
        } else {
            let { edits } = this.state
            if (edits.has(obj)) {
                edits.delete(obj)
            } else {
                edits.set(obj, obj)
            }
            edits = new Map<any, any>(edits)
            this.setState({ edits })
        }
    }
    onSave = (key: any, obj: any) => {
        let { edits } = this.state
        edits.delete(key)
        edits = new Map<any, any>(edits)
        this.setState({ edits })
        if (this.props.onEdit) {
            this.props.onEdit(key, obj)
        }
    }
    onChange = (obj: any) => (fieldName: string, value: any) => {
        let { edits } = this.state
        let newEdit: any = Object.create(obj)
        let edit = Object.assign(newEdit, edits.get(obj))
        // this.props.dataTable.columns
        if (edit) {
            edit[fieldName] = value
            if (this.props.dataTable.customEditAction) {
                this.props.dataTable.customEditAction(fieldName, edit)
            }
            edits.set(obj, edit)
            edits = new Map<any, any>(edits)
            this.setState({ edits })
        }
    }
    clearSelects = () => {
        this.setState({ selected: [] })
    }
    onToggleSelectAll = () => {
        const newState = { selected: [] }
        const selectIdField = this.props.selectIdField
        if (this.state.selected.length == 0 && selectIdField) {
            newState.selected = this.props.data.map((x: any) => x[selectIdField])
        }
        this.setState(newState)
        if (this.props.onSelectedChanged) this.props.onSelectedChanged(newState.selected)
    }
    onToggleSelect = (obj: any) => {
        const selectIdField = this.props.selectIdField
        if (selectIdField) {
            const id = obj[selectIdField]
            if (id) {
                let newState
                if (this.state.selected.some((x) => x == obj[selectIdField])) {
                    newState = { selected: this.state.selected.filter((x) => x != id) }
                } else {
                    newState = { selected: this.state.selected.concat([id]) }
                }
                this.setState(newState)
                if (this.props.onSelectedChanged) this.props.onSelectedChanged(newState.selected)
            }
        }
    }
    onSortChanged = (sortField: string, sortType: string) => {
        this.setState({ sortField, sortType })
        if (this.props.onSortChanged) {
            this.props.onSortChanged(sortField, sortType)
        }
    }
    renderCellDisplay = (obj: any, c: DataTableColumnModel) => {
        if (c.customRenderer) {
            return c.customRenderer(obj)
        } else {
            return obj[c.fieldName] === undefined
                ? c.placeholder
                    ? obj[c.placeholder]
                    : ''
                : moment.isMoment(obj[c.fieldName])
                    ? (obj[c.fieldName] as moment.Moment).format('DD.MM.YYYY')
                    : obj[c.fieldName]
        }
    }
    render() {
        const data = this.sort(this.props.data)
        const isHeaderGroups = this.props.dataTable.headerGroups && this.props.dataTable.headerGroups.length > 0
        const sums = this.getSumsRowData(this.props.dataTable.columns, this.props.data)
        const tableHeaderHeight = this.state.headerHeight
        const tableTotalHeight = this.props.tableTotalHeight //.getTableBodyHeight()
        const tableBodyHeight = tableTotalHeight - tableHeaderHeight
        const _this = this
        console.log(this.props.dataTable.headerGroups)
        return (
            <Scrollbars style={{ height: tableTotalHeight }} className="table-wrapper">
                <ReactResizeDetector handleWidth handleHeight onResize={() => this.handleHeaderResize(_this)}>
                    <div className="table-header" ref={this.setHeaderRef} style={{ minWidth: this.props.width }}>
                        <table className="table-fixed" style={{ minWidth: this.props.width }}>
                            <thead>
                                {isHeaderGroups && (
                                    this.props.dataTable.headerGroups.map(((hgg, index) => {
                                        <tr key={index}>
                                            {this.props.onSelectedChanged && <th className="data-table-row-action"></th>}
                                            {this.props.onEdit && <th className="data-table-row-action"></th>}
                                            {this.props.onDelete && <th className="data-table-row-action"></th>}
                                            {hgg
                                                .reduce((res: any[], next) => {
                                                    res.push({
                                                        colsBefore:
                                                            res.length > 0
                                                                ? res[res.length - 1].group.ColSpan +
                                                                res[res.length - 1].colsBefore
                                                                : 0,
                                                        group: next,
                                                    })
                                                    return res
                                                }, [])
                                                .map((g: any, index) => {
                                                    const x = g.group
                                                    const width = this.props.dataTable.columns
                                                        .filter((x) => !x.isHidden)
                                                        .slice(g.colsBefore, g.colsBefore + x.ColSpan)
                                                        .reduce((res, next) => (res += next.width), 0)
                                                    return (
                                                        <th style={{ width }} colSpan={x.ColSpan} key={index}>
                                                            {x.Title}
                                                        </th>
                                                    )
                                                })}
                                        </tr>
                                    }))
                                )}
                                <tr>
                                    {this.props.onSelectedChanged && (
                                        <th className="no-sort" style={{ width: 30 }}>
                                            <CheckBox
                                                isChecked={this.state.selected.length > 0}
                                                styles={{ display: 'block' }}
                                                onClick={this.onToggleSelectAll}
                                            />
                                        </th>
                                    )}
                                    {this.props.onEdit && <th className="no-sort data-table-row-action"></th>}
                                    {this.props.onDelete && <th className="no-sort data-table-row-action"></th>}
                                    {this.props.dataTable.columns
                                        .filter((x) => !x.isHidden)
                                        .map((obj, index) => {
                                            const filterFieldName = obj.filterFieldName || obj.fieldName
                                            return (
                                                <th
                                                    className={
                                                        obj.sortable || obj.filterType != FilterType.NONE
                                                            ? ''
                                                            : 'no-sort'
                                                    }
                                                    key={index}
                                                    style={{ width: obj.width }}
                                                >
                                                    {obj.filterType != FilterType.NONE &&
                                                        this.props.onFilterValueChange &&
                                                        filterFactory(
                                                            obj.filterType,
                                                            obj.filterValue
                                                                ? obj.filterValue
                                                                : this.props.filter[filterFieldName],
                                                            (value: any) => {
                                                                if (this.props.onFilterValueChange) {
                                                                    this.props.onFilterValueChange(
                                                                        filterFieldName,
                                                                        value,
                                                                    )
                                                                }
                                                            },
                                                            obj.filterOptions,
                                                        )}
                                                    {obj.sortable && (
                                                        <div className="data-table-sort">
                                                            <i
                                                                className={`strokable fa fa-sort-up${this.state.sortField == obj.fieldName &&
                                                                    this.state.sortType == SORT_ASC
                                                                    ? ' active'
                                                                    : ''
                                                                    }`}
                                                                onClick={() =>
                                                                    this.onSortChanged(obj.fieldName, SORT_ASC)
                                                                }
                                                            ></i>
                                                            <i
                                                                className={`strokable fa fa-sort-down${this.state.sortField == obj.fieldName &&
                                                                    this.state.sortType == SORT_DESC
                                                                    ? ' active'
                                                                    : ''
                                                                    }`}
                                                                onClick={() =>
                                                                    this.onSortChanged(obj.fieldName, SORT_DESC)
                                                                }
                                                            ></i>
                                                        </div>
                                                    )}
                                                    {obj.customHeaderComponent
                                                        ? obj.customHeaderComponent()
                                                        : obj.displayName}
                                                </th>
                                            )
                                        })}
                                    {/* <th style={{width:wScroll, maxWidth:wScroll, padding:0}}></th>                                    */}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </ReactResizeDetector>
                <Scrollbars
                    style={{
                        minWidth: this.props.width,
                        height: tableBodyHeight,
                        zIndex: 1,
                    }}
                >
                    <table className="table-fixed">
                        {this.props.customBody ? (
                            this.props.customBody
                        ) : (
                            <tbody>
                                {data.map((obj: any, index: number) => {
                                    const isSelected = this.props.onSelectedChanged
                                        ? ((this.state.selected.indexOf(obj[this.props.selectIdField || '']) >=
                                            0) as any)
                                        : false
                                    const editObj = this.props.onEdit && this.state.edits.get(obj)
                                    const dt = this.props.dataTable
                                    const isEditableObject =
                                        this.props.onEdit && dt.isObjectEditableClause && dt.isObjectEditableClause(obj)
                                    return (
                                        <tr
                                            onDoubleClick={
                                                this.props.onEdit && !editObj && typeof isEditableObject == undefined
                                                    ? () => this.onToggleEditMode(obj)
                                                    : undefined
                                            }
                                            key={`${obj.Id}-${index}`}
                                            className={`${obj.customClass || ''}${isSelected ? ' selected' : ''}${this.props.dataTable.customRowClassFunction ? " " + this.props.dataTable.customRowClassFunction(obj) :""}`}
                                        >
                                            {this.props.onSelectedChanged && (
                                                <td style={{ width: 30 }}>
                                                    <CheckBox
                                                        styles={{ display: 'block' }}
                                                        onClick={() => this.onToggleSelect(obj)}
                                                        isChecked={isSelected}
                                                    />
                                                </td>
                                            )}
                                            {this.props.onEdit && (
                                                <td className="data-table-row-action">
                                                    {isEditableObject ? (
                                                        <Tooltip
                                                            text={
                                                                dt.localizator
                                                                    ? dt.localizator(isEditableObject)
                                                                    : isEditableObject
                                                            }
                                                        >
                                                            <i className={`disabled fa fa-pencil`}></i>
                                                        </Tooltip>
                                                    ) : (
                                                        <i
                                                            onClick={() => {
                                                                this.onToggleEditMode(obj)
                                                            }}
                                                            className={`clickable fa ${editObj ? 'fa-ban' : 'fa-pencil'
                                                                }`}
                                                        ></i>
                                                    )}
                                                    {editObj && (
                                                        <i
                                                            className={`clickable fa fa-save`}
                                                            onClick={() => {
                                                                this.onSave(obj, editObj)
                                                            }}
                                                        ></i>
                                                    )}
                                                </td>
                                            )}
                                            {this.props.onDelete && (
                                                <td className="data-table-row-action">
                                                    {!editObj && (
                                                        <i
                                                            onClick={() => {
                                                                if (this.props.onDelete) this.props.onDelete(obj)
                                                            }}
                                                            className="fa fa-ban clickable"
                                                        ></i>
                                                    )}
                                                </td>
                                            )}
                                            {dt.columns
                                                .filter((x) => !x.isHidden)
                                                .map((c, index) => {
                                                    const title = c.title && obj[c.title]
                                                    const fontWeight =
                                                        c.customRenderer ||
                                                            !c.placeholder ||
                                                            (!obj[c.fieldName] && c.placeholder)
                                                            ? 'normal'
                                                            : 'bold'
                                                    const color = c.customRenderer
                                                        ? undefined
                                                        : obj[c.fieldName]
                                                            ? c.placeholder
                                                                ? '#333'
                                                                : '#797979'
                                                            : '#ccc'
                                                    return editObj &&
                                                        c.isEditable &&
                                                        (!c.isEditableClause || c.isEditableClause(editObj)) ? (
                                                        <td
                                                            key={`${obj.Id}-${index}`}
                                                            className="data-table-td-edit"
                                                            style={{
                                                                width: c.width,
                                                            }}
                                                        >
                                                            {c.customEditComponent ? (
                                                                c.customEditComponent(
                                                                    obj,
                                                                    (value: any, fieldName: string) => {
                                                                        this.onChange(obj)(fieldName, value)
                                                                    },
                                                                )
                                                            ) : (
                                                                <InputFactory
                                                                    placeholder={obj[c.placeholder || '']}
                                                                    key={`${obj.Id}-${index}`}
                                                                    type={c.editType || InputType.Text}
                                                                    isClearable={c.isClearable}
                                                                    options={c.editOptions}
                                                                    value={editObj[c.fieldName]}
                                                                    onChange={(value: any) => {
                                                                        this.onChange(obj)(c.fieldName, value)
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            title={title}
                                                            key={`${obj.Id}-${index}`}
                                                            style={
                                                                Object.assign(
                                                                    {
                                                                        width: c.width,
                                                                        fontWeight: fontWeight,
                                                                        color: color,
                                                                    },
                                                                    c.customCellStyleFunction
                                                                        ? c.customCellStyleFunction(obj)
                                                                        : {},
                                                                ) as any
                                                            }
                                                            className={
                                                                c.customCellClassFunction
                                                                    ? c.customCellClassFunction(obj)
                                                                    : ''
                                                            }
                                                        >
                                                            {this.renderCellDisplay(obj, c)}
                                                        </td>
                                                    )
                                                })}
                                        </tr>
                                    )
                                })}
                                {this.props.dataTable.showSum && this.props.data.length > 0 && (
                                    <tr>
                                        {sums.map((cell: any, index: number) => {
                                            return (
                                                <td key={index} colSpan={cell.colSpan}>
                                                    {cell.value ? round(cell.value) : cell.empty ? '' : '0'}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )}
                                {this.props.dataTable.appendRows}
                            </tbody>
                        )}
                    </table>
                </Scrollbars>
            </Scrollbars>
        )
    }
}
