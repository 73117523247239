import * as React from 'react'
import FilterIcon from './FilterIcon'
import { FilterBody } from './FilterBody'
import { SelectOptionModel } from '../../Select/SelectOptionModel'

interface Props {
    value: any[]
    options: any[]
    onSubmit: (value: string[]) => void
}
interface State {
    value?: any[]
    isOpen: boolean
    search: string
    optionsHash: {}
}
class SelectFilter extends React.Component<Props, State> {
    private searchInput: React.RefObject<HTMLInputElement>
    state = {
        isOpen: false,
        value: undefined as unknown as any[],
        optionsHash: this.props.options.reduce((res, option) => {
            res[option.value] = option.label
            return res
        }, {}),
        search: '',
    }
    constructor(props: Props) {
        super(props)
        this.searchInput = React.createRef<HTMLInputElement>()
    }
    setOptionsHash = (options: SelectOptionModel[]) => {
        const optionsHash = options.reduce((res, option) => {
            res.set(option.value, option.label)
            return res
        }, new Map<any, any>())
        this.setState({ optionsHash })
    }
    componentWillReceiveProps(nextProps: Props) {
        if (this.state.value != nextProps.value) {
            this.setState({ value: nextProps.value })
        }
        if (this.props.options != nextProps.options) {
            this.setOptionsHash(nextProps.options)
        }
    }
    close = () => {
        this.setState({ isOpen: false, value: this.props.value })
    }
    clear = () => {
        this.setState({ value: [] })
    }
    clearSearch = () => {
        this.setState({ search: '' })
    }
    selectAll = (options: SelectOptionModel[]) => {
        return () => {
            if (this.state.search && this.state.search.length > 0) {
                const ids = options.map((x) => x.value)
                this.setState({ value: ids })
            }
        }
    }
    selectOption = (id: any) => {
        return () => {
            this.setState({ value: id })
            this.props.onSubmit(id)
            this.close()
        }
    }
    handleClickOutside() {
        if (this.state.isOpen) {
            this.close()
        }
    }
    onSubmit = () => {
        this.props.onSubmit(this.state.value)
        this.close()
    }
    render() {
        const { value, isOpen, search, optionsHash } = this.state
        let options = this.props.options
        if (search && search.length > 0) {
            options = options.filter((x) => x.isParent || x.label.toLowerCase().indexOf(search) >= 0)
        }
        const selectedOption = value ? optionsHash[value as any] : undefined
        return (
            <div className="data-table-filter">
                <FilterIcon
                    title={`${selectedOption ? selectedOption.label : ''}`}
                    isActive={selectedOption}
                    isOpen={isOpen}
                    onClick={() => {
                        this.setState({ isOpen: !isOpen, value: this.props.value }, () => {
                            if (this.searchInput && this.searchInput.current) {
                                this.searchInput.current.focus()
                                this.searchInput.current.select()
                            }
                        })
                    }}
                />
                {isOpen && (
                    <FilterBody onClose={this.close}>
                        <input
                            ref={this.searchInput}
                            value={search}
                            onChange={(e) => this.setState({ search: e.target.value.toLowerCase() })}
                        />
                        <i className="data-table-filter-clear" onClick={this.clearSearch}></i>
                        <div className="data-filter-multiselect-area">
                            {options.map((x, index) => {
                                return x.isParent ? (
                                    <p key={`p-${index}`} className={`parent data-filter-multiselect-option`}>
                                        {x.label}
                                    </p>
                                ) : (
                                    <p
                                        key={x.value}
                                        className={`${x.isChild ? 'child ' : ''}data-filter-multiselect-option${value == x.value ? ' active' : ''
                                            }`}
                                        onClick={this.selectOption(x.value)}
                                    >
                                        {x.label}
                                    </p>
                                )
                            })}
                        </div>
                        <div className="clear"></div>
                    </FilterBody>
                )}
            </div>
        )
    }
}
export default SelectFilter
