import * as React from 'react'
import {SelectOptionModel} from './SelectOptionModel'
import SelectOption from './SelectOption'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
interface Props {
    isOpened: boolean
    isMultiple: boolean
    selectedOption?: SelectOptionModel | SelectOptionModel[]
    preSelectedItem: SelectOptionModel
    options: SelectOptionModel[]
    onSelect: (option: SelectOptionModel) => void
    onHover: (option: SelectOptionModel) => void
    onRemove: (option: SelectOptionModel) => void
}

const SelectContainer = (props: Props) => {
    const activeOption = React.useRef()
    React.useEffect(() => {
        ensureActiveItemVisible()
    }, [props.preSelectedItem, props.selectedOption])

    const scrollElementIntoViewIfNeeded = (domNode: any) => {
        if (domNode) {
            domNode.scrollIntoView({ block: 'nearest' })
        }
    }
    const ensureActiveItemVisible = () => {
        const itemComponent = activeOption
        if (itemComponent) {
            const domNode = ReactDOM.findDOMNode(itemComponent.current)
            scrollElementIntoViewIfNeeded(domNode)
        }
    }
    return (
        <>
            <ul className="select-options" style={{ display: props.isOpened ? 'block' : 'none' }}>
                {props.options.map((option, index) => (
                    <SelectOption
                        option={option}
                        level={option.level}
                        isSelected={
                            props.isMultiple
                                ? (props.selectedOption as SelectOptionModel[]).some((x) => x == option)
                                : option === props.selectedOption
                        }
                        isPreselected={option === props.preSelectedItem}
                        onSelect={props.onSelect}
                        onHover={props.onHover}
                        key={`${option.value}-${index}`}
                    >
                        {option.customRenderer 
                            ? option.customRenderer(option) 
                            : option.label}
                    </SelectOption>
                ))}
            </ul>
            {props.isMultiple && props.selectedOption && (props.selectedOption as SelectOptionModel[]).length > 0 && (
                <div className="custom_select_result">
                    {(props.selectedOption as SelectOptionModel[]).map((option) => (
                        <span className="multiple-value-item" key={option.value?.toString()}>
                            {option.url ? <Link target='_blank' to={option.url}>{option.label}</Link> : option.label}
                            <i onClick={() => props.onRemove(option)}></i>
                        </span>
                    ))}
                </div>
            )}
        </>
    )
}
export default SelectContainer
